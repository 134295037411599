exports.fonts = {
    reg : `
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        font-size: 14px;
        line-height : 1.2em;
    `,
    bold : `
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 14px;
        line-height : 1.2em;
    `,
    title : `
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 25px;
        line-height : 1.2em;
    `
}

exports.colors = {
    loBlue : '#143C6B',
    midBlue : "#11ABDD",
    hiBlue : "#EAF3F6",
    mid : "#CECECE",
    midHi : "#F5F5F5",
    green : "#63C17C",
    midLo : "#7C7C7C",
    orange : "#FF8900",
    dark : "#383838"
}

exports.effects = {
    shadow1 : `
    box-shadow: 0px 3px 6px rgba(0,0,0,0.16);    
`,
    tabShadow : `
        box-shadow: 0px 3px 6px rgba(0,0,0,0.16), inset 0px -4px 6px rgba(0,0,0,0.3);    
`
}